var $ = require('jquery');

var name = "JIRA";

var baseUrl = function () {
  var result = window.location.origin;
  if (AJS && AJS.params && AJS.params.baseURL){
    result = AJS.params.baseURL;
  } else if ($("input[title='baseURL']") && $("input[title='baseURL']").val()){
    result = $("input[title='baseURL']").val();
  }
  return result;
};

var isEligible = function () {
    return $("meta[name='application-name'][ content='JIRA']").length > 0;
};

var getSelectedIssueKeyList = function () {

    //Browse
    if (/.*\/browse\/.*/g.test(document.URL)) {
        return [document.URL.match(/.*\/browse\/([^?]*).*/)[1]];
    }

    //Project
    if (/.*\/projects\/.*/g.test(document.URL)) {
        return [document.URL.match(/.*\/projects\/[^\/]*\/[^\/]*\/([^?]*).*/)[1]];
    }

    //Issues
    if (/.*\/issues\/.*/g.test(document.URL)) {

        var issues = $('.issue-list > li').map(function () {
            return $(this).attr('data-key');
        });

        //backward compatibility
        if (issues.empty()) {
            issues = $('tr[data-issuekey]').map(function () {
                return $(this).attr('data-issuekey');
            });
        }

        return issues;
    }

    // RapidBoard
    if (/.*\/secure\/RapidBoard.jspa.*/g.test(document.URL)) {
        return $('div[data-issue-key].ghx-selected').map(function () {
            return $(this).attr('data-issue-key');
        });
    }

    return [];
};

var getIssueData = function (issueKey) {
    // https://docs.atlassian.com/jira/REST/latest/

    var urlAgile = baseUrl() + '/rest/agile/1.0/issue/' + issueKey + '?expand=renderedFields,names';
    var urlClassic = baseUrl() + '/rest/api/2/issue/' + issueKey + '?expand=renderedFields,names';

    //console.log("Issue: " + issueKey + " Loading...");
    return new Promise(function (fulfill, reject){
        // console.log("IssueUrl: " + urlAgile);
        $.getJSON(urlAgile).done(fulfill).fail( function() {
                // console.log("IssueUrl: " + urlClassic);
                $.get(urlClassic).done(fulfill).fail(reject);
            });
    }).then(function (responseData) {
        console.log("Issue: " + issueKey + " Loaded!");
        $.each(responseData.names, function (fieldKey, fieldName) {
            // try to fetch custom fields
            if (fieldKey.startsWith("customfield_")) {
                // console.log("Fieldkey: " + fieldKey + " Fieldname: "+ fieldName.toCamelCase());
                if( !responseData.fields.estimate && ['storyPoints', 'storyPunkte', 'backlogEstimate', 'storyPointEstimate'].indexOf(fieldName.toCamelCase()) > -1 ){
                    responseData.fields.estimate = responseData.fields[fieldKey];
                }
                if( !responseData.fields.story && ['story'].indexOf(fieldName.toCamelCase()) > -1 ){
                    responseData.fields.story = responseData.fields[fieldKey];
                    responseData.renderedFields.story = responseData.renderedFields[fieldKey];
                }
                if( !responseData.fields.acceptanceCriteria && ['acceptanceCriteria'].indexOf(fieldName.toCamelCase()) > -1 ){
                    responseData.fields.acceptanceCriteria = responseData.fields[fieldKey];
                    responseData.renderedFields.acceptanceCriteria = responseData.renderedFields[fieldKey];
                }
                if( !responseData.fields.epic && ['epicLink', 'eposVerknüpfung'].indexOf(fieldName.toCamelCase()) > -1 ){
                    responseData.fields.epic = {};
                    responseData.fields.epic.key = responseData.fields[fieldKey];
                    responseData.fields.epic.name = "";
                }
            }
        });
        return responseData;
    });
};

var getCardData = function (issueKey) {
    var promises = [];
    var issueData = {};

    promises.push(getIssueData(issueKey).then(function (data) {
        var promises = [];
        issueData.key = data.key;
        issueData.type = data.fields.issuetype.name.toLowerCase();
        issueData.story = data.fields.story;
        issueData.acceptanceCriteria = data.fields.acceptanceCriteria;
        // console.log("IssueData.story: " + issueData.story + "issueType: " + data.fields.issuetype.name.toLowerCase());
        issueData.summary = data.fields.summary;
        // Build description-text
        issueData.description = '';
        if( data.fields.issuetype.name.toLowerCase()=='story' && data.fields.story != null && data.fields.story != 'As a \r\nI want to \r\nSo that ')
        {
            issueData.description = issueData.description + '<strong>Story</strong>' + data.renderedFields.story;
        }
        if( data.fields.issuetype.name.toLowerCase()=='story' && data.fields.acceptanceCriteria != null) {
            issueData.description = issueData.description + '<strong>Acceptance Criteria</strong>' + data.renderedFields.acceptanceCriteria;
        }
        if( data.renderedFields.description != "") {
            issueData.description = issueData.description + '<strong>Description</strong><p>'+ data.fields.description+ '</p>';}
        // console.log("issueData.Description: "+issueData.description);
        issueData.labels = data.fields.labels;
        

        if (data.fields.assignee) {
            issueData.assignee = data.fields.assignee.displayName.replace(/\[[^[]*\]/,'');
            var avatarUrl = data.fields.assignee.avatarUrls['48x48'];
            if (avatarUrl.indexOf("ownerId=") >= 0) {
                issueData.avatarUrl = avatarUrl;
            }
        }

        if (data.fields.duedate) {
            issueData.dueDate = new Date(data.fields.duedate);
        }

        issueData.hasAttachment = data.fields.attachment ? data.fields.attachment.length > 0 : false;
        issueData.estimate = data.fields.estimate;

        if (data.fields.parent) {
            issueData.superIssue = data.fields.parent.key + ' ' + data.fields.parent.fields.summary;
        } else if (data.fields.epic && data.fields.epic.key) {
            issueData.superIssue = data.fields.epic.key + ' ' + data.fields.epic.name;
        }

        issueData.url = baseUrl() + "/browse/" + issueData.key;
        issueData.projectName = data.fields.project.name;
        issueData.projectLogo = data.fields.project.avatarUrls['48x48'];

        return Promise.all(promises);
    }));

    return Promise.all(promises).then(function () {
        return issueData;
    });
};

module.exports = {
    name: name,
    isEligible: isEligible,
    getSelectedIssueKeyList: getSelectedIssueKeyList,
    getCardData: getCardData,
    getIssueData: getIssueData
};
